import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar';
import RollingDiceIcon from './RollingDiceIcon'
const Challengecomp = ({challenge}) => {

    const { userId1, userId2 } = challenge;

    const apiUrl = process.env.REACT_APP_API_LINK;

    const [user1, setUser1]=useState('');
    const [user2, setUser2]=useState('');

    const winning_amount=challenge["gameAmount"]*2*.9;
    // console.log(winning_amount)
   
    const fetchUserDetails = async (userid) => {  // Remove the object destructuring here
      // console.log(userid)
      try {
          const response = await axios.post(`${apiUrl}user/getuserinfousingid`, { userId: userid }, {
              withCredentials: true
          });
          // console.log(response)
          return response.data.user;
      } catch (error) {
          console.error('Error fetching user details:', error);
      }
  };

    useEffect(() => {
      const fetchUsers = async () => {
        // console.log(userId1)
          const userDetails1 = await fetchUserDetails({ userid: userId1 });
          const userDetails2 = await fetchUserDetails({userid: userId2});
          setUser1(userDetails1);
          setUser2(userDetails2);
      };

      fetchUsers();
  }, [userId1, userId2]); 
  return (
    <div className=' p-2 m-2  flex border-2 align-center items-center border-white text-white  '>
      <div className='flex gap-2 p-2 border border-green-300 align-center items-center rounded-md '>
      <Avatar sx={{ width: 24, height: 24 }}>{user1 && user1.username.charAt(0).toUpperCase()}</Avatar>
         {user1 && user1.username} 
      </div>
      <div className='flex flex-col items-center aling-center'>
      <RollingDiceIcon />
        <span >Wining Amount  {winning_amount}</span>
      </div>
      
        
        <div className='flex gap-2 p-2 border border-blue-300 align-center items-center rounded-md '>
        <Avatar sx={{ width: 24, height: 24 }}>{user1 && user1.username.charAt(0).toUpperCase()}</Avatar>
           {user2 && user2.username}
        </div>
  </div>
  )
}

export default Challengecomp
