// Login.jsx
import React, { useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import api from '../api/api'; // Axios instance with cookies
import { useDispatch } from 'react-redux';
import { setUser } from '../redux/userSlice';
import { setWallet } from '../redux/walletSlice';
import CircularProgress from '@mui/material/CircularProgress';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpCode, setOtp] = useState('');
  const [loaderState, setLoaderState] = useState(false);
  const [confirmationSent, setConfirmationSent] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSignInSubmit = async (e) => {
    e.preventDefault();
    setLoaderState(true);
    try {
      await api.post('/auth/sendotp', { phoneNumber: `+91 ${phoneNumber}` });
      setConfirmationSent(true);
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
    setLoaderState(false);
  };

  const onSubmitOtp = async (e) => {
    e.preventDefault();
    setLoaderState(true);
    try {
      await api.post('/auth/verifyotp', { phoneNumber: `+91 ${phoneNumber}`, otpCode });

      const userResponse = await api.get('/user/getuserinfo');
      dispatch(setUser(userResponse.data.user)); // Store user in Redux

      const walletResponse = await api.get('/wallet/getwalletdetails');
      dispatch(setWallet(walletResponse.data.wallet)); // Store wallet in Redux

      navigate('/'); // Navigate to home after login
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
    setLoaderState(false);
  };

  return (
    <div className="relative bg-black h-screen flex flex-col items-center justify-center overflow-hidden">
      <div id="recaptcha-container"></div>
      <div>
        <video
          className="absolute inset-0 object-cover h-2/3"
          src="https://media.istockphoto.com/id/164185925/video/pair-of-aces-falling-on-a-table.mp4?s=mp4-640x640-is&k=20&c=eKErU1ad6pET2lsKyKUL2o2Li4uSrEzwCu6EY-6xwdQ="
          autoPlay loop muted
        />
      </div>
      <div className="absolute top-4 left-16 transform -translate-x-1/2 z-20">
        <Link to="/">
          <button className="px-6 py-2 border-white border-2 text-black rounded hover:text-black">
            <IoIosArrowBack style={{ color: 'white' }} />
          </button>
        </Link>
      </div>
      <div className="relative z-20 text-center w-full">
        <h2 className="font-bold text-2xl text-white mb-4 font-sans pb-5">
          Sign in or Sign Up
        </h2>
        <div className="bg-white rounded-xl ml-3 mr-3 h-16 flex place-content-center items-center">
          {!confirmationSent ? (
            <div className="border-gray-400 border-2 rounded-sm flex">
              <p className="bg-gray-300 p-2">+91</p>
              <input
                className="pl-5 bg-white"
                placeholder="Mobile number"
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          ) : (
            <div className="border-gray-400 border-2 rounded-sm flex">
              <p className="bg-gray-300 p-2">OTP</p>
              <input
                className="pl-5 bg-white"
                placeholder="Enter Your OTP here"
                type="tel"
                value={otpCode}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className="z-20 text-white bg-green-500 font-sans text-xl font-semibold p-3 rounded-xl m-5">
          {!confirmationSent ? (
            <button type="submit" onClick={onSignInSubmit}>
              {!loaderState ? <p>Send OTP</p> : <CircularProgress color="inherit" />}
            </button>
          ) : (
            <button type="submit" onClick={onSubmitOtp}>
              {!loaderState ? <p>Verify OTP</p> : <CircularProgress color="inherit" />}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
