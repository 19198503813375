// Hero.js
import React from "react";
import { gamesData } from "../Data/Data";
import GameCard from "./GameCard";

const Hero = () => {
  return (
    <>
   
    <div className=" m-5 flex flex-col items-center justify-center">
      {/* <h3 className="text-lg font-semibold">Our Games</h3> */}
      <span className="flex items-center justify-center text-xs font-light gap-1 m-2">
      </span>

      <div className="">
        {gamesData.map((game) => (
          <GameCard key={game.id} game={game} />
        ))}
      </div>
    </div>
    </>
  );
};

export default Hero;
