// redux/walletSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  wallet: null, // Wallet data stored temporarily in Redux
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWallet: (state, action) => {
      state.wallet = action.payload;
    },
    clearWallet: (state) => {
      state.wallet = null;
    },
  },
});

export const { setWallet, clearWallet } = walletSlice.actions;
export const selectWallet = (state) => state.wallet.wallet;

export default walletSlice.reducer;
