import React from 'react';
import { Box, Typography } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups'; // Example icon

const LiveGames = ({ liveChallengeCount }) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      {/* Blinking "Live" text */}
      <Typography
        variant="h6"
        sx={{
          color: 'green',
          fontWeight: 'bold',
          animation: 'blink 1s infinite',
        }}
      >
        Live
      </Typography>

      {/* Icon with green color */}
      <GroupsIcon sx={{ color: 'green', fontSize: 30 }} />

      {/* Live challenge count */}
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        {liveChallengeCount}
      </Typography>

      {/* Adding blink animation styles directly in JSX */}
      <style>{`
        @keyframes blink {
          0% { opacity: 1; }
          50% { opacity: 0; }
          100% { opacity: 1; }
        }
      `}</style>
    </Box>
  );
};

export default LiveGames;
