import React, { useEffect, useState } from 'react';
import CasinoRoundedIcon from '@mui/icons-material/CasinoRounded';
import { keyframes } from '@mui/system';

// Define keyframes for 3D rolling animation
const roll3DAnimation = keyframes`
  0% { transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
  25% { transform: rotateX(90deg) rotateY(0deg) rotateZ(45deg); }
  50% { transform: rotateX(180deg) rotateY(90deg) rotateZ(90deg); }
  75% { transform: rotateX(270deg) rotateY(180deg) rotateZ(135deg); }
  100% { transform: rotateX(360deg) rotateY(360deg) rotateZ(180deg); }
`;

const RollingDiceIcon3D = () => {
  const [rolling, setRolling] = useState(false);

  useEffect(() => {
    // Set an interval to trigger the 3D rolling animation every 3 seconds
    const interval = setInterval(() => {
      setRolling(true);
      setTimeout(() => setRolling(false), 1000); // Stop rolling after 1 second
    }, 3000); // Interval of 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <CasinoRoundedIcon
      sx={{
        mr: 1,
        color: 'white',
        fontSize: 50, // Adjust size as needed
        animation: rolling ? `${roll3DAnimation} 1s ease-in-out` : 'none',
        transformStyle: 'preserve-3d', // For 3D effect
      }}
    />
  );
};

export default RollingDiceIcon3D;
