import React from 'react';
import {useSelector} from 'react-redux'
import { selectIsAuthenticated } from '../redux/userSlice';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
   const isAuthenticated =useSelector(selectIsAuthenticated);
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default ProtectedRoute;
