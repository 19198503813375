import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { CiMenuFries } from "react-icons/ci";
import Sidebar from './Sidebar';
import { selectUser } from '../redux/userSlice';
import { useSelector } from 'react-redux';
import { selectWallet } from '../redux/walletSlice';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
  // Selector for wallet from Redux

const Navbar = () => {
  const [shadow, setShadow] = useState(false);
  const [open, setOpen] = useState(false);

  // Select user and wallet from Redux store
  const user = useSelector(selectUser);
  const wallet = useSelector(selectWallet);  

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShadow(true);
      } else {
        setShadow(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`flex items-center justify-between p-4 shadow-md ${shadow ? 'shadow-black' : ''} bg-red-800 sticky top-0 w-full z-50`}>
        <div className='w-1/2 flex items-center'>
          {user ? (
            <CiMenuFries color='white' size='2em' onClick={handleOpen} />
          ) : (
            <Link to='/'>
              <img className='w-8 h-8 object-contain' src='https://cdn.pixabay.com/photo/2021/10/11/17/26/e-sports-6701392_640.png' alt='Logo' />
            </Link>
          )}
        </div>
        <div className='w-1/2 flex justify-end space-x-4'>
          {user ? (
            <>
            <Link to="wallet">
              <div className="text-white border-white border-2 p-2 rounded-md">
                <p><AccountBalanceWalletOutlinedIcon />: {wallet ? wallet.totalAmount : "Loading..."}</p>
              </div>
              </Link>
            </>
          ) : (
            <>
              <Link to='/login'>
                <button className='px-4 py-2 border-2 rounded-md text-white hover:bg-black hover:underline'>Signup</button>
              </Link>
              <Link to='/login'>
                <button className='px-4 py-2 border-green border-2 rounded-md text-white hover:bg-black hover:underline'>Login</button>
              </Link>
            </>
          )}
        </div>
      </div>
      <Sidebar open={open} setopen={setOpen} />
    </>
  );
}

export default Navbar;
