import React, { useRef } from "react";
import image from "../images/images.jpeg";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { selectUser } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/userSlice";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import DoneIcon from '@mui/icons-material/Done';
import Button from '@mui/material/Button';

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const handlelogout = () => {
    Cookies.remove("authToken");
    dispatch(logout());
    navigate("/");
  };

  // Using a ref to control the carousel
  const carouselRef = useRef(null);

  const handleNext = () => {
    // Move to the next slide
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handlePrev = () => {
    // Move to the previous slide
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };
  return (
    <div className="h-screen ">
      <div className="flex flex-col justify-center items-center ">
        <img src={image} alt="profile" className="rounded-full h-24 w-24 " />
        <p className="text-gray-600 ">{user?.phoneNumber}</p>
        <div className="flex justify-center items-center">
          <p>{user?.username}</p>
        </div>
      </div>

      <Link to="/wallet">
        <div className=" flex items-center justify-center border m-3 ">
          <img
            src="https://cdn.pixabay.com/photo/2017/01/31/19/58/purse-2026844_1280.png"
            alt=""
            className="w-16 h-16 "
          />
          <span className="underline">Wallet</span>
        </div>
      </Link>

      <div className=" bg-white mt-7">
        <h3 className="text-md font  p-2">Complete Profile</h3>
        <Carousel
          ref={carouselRef}
          autoPlay
          infiniteLoop
          showThumbs={false}
          showStatus={false}
          className="p-3"
        >
          <div>
            <Link to="/wallet">
              <div className="flex items-center justify-center gap-5 border p-4 mx-10 shadow-md transition duration-500">
                <div className="w-1/3">
                  <img
                    src="https://media.istockphoto.com/id/1952831275/vector/kyc-or-know-your-customer-with-business-verifying-the-identity-of-its-clients-concept-at-the.jpg?s=612x612&w=is&k=20&c=DzbEBuYvSZNLNv4_Tl7ZTOnQj9gTu25Bst3BIcuIbt4="
                    alt="Complete KYC"
                    className="w-12 h-12"
                  />
                </div>
                <span className="underline text-lg">Complete KYC</span>
              </div>
            </Link>
          </div>

          <div>
            <Link to="/wallet">
              <div className="flex items-center justify-center gap-5 border p-4 mx-10 shadow-md transition duration-500">
                <div className="w-1/3">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/640px-Gmail_icon_%282020%29.svg.png"
                    className="w-12 h-12"
                  />
                </div>
                <span className="underline text-lg">Update Gmail</span>
              </div>
            </Link>
          </div>
          {/* You can add more carousel items here */}
        </Carousel>

        {/* Next and Previous buttons */}
        <button
          onClick={handlePrev}
          className="absolute left-2 top-1/2 transform -translate-y-1/2  p-2 rounded-full"
        >
          <ArrowBackIosNewIcon />
        </button>
        <button
          onClick={handleNext}
          className="absolute right-2 top-1/2 transform -translate-y-1/2   p-2 rounded-full"
        >
          <ArrowForwardIosIcon />
        </button>
      </div>

      <div className="m-3 border  p-2  text-lg  ">
        <div className=" ">
            <InputLabel htmlFor="input-with-icon-adornment">
          Use Refer Code
        </InputLabel>
        <Input
          id="input-with-icon-adornment"
          startAdornment={
            <InputAdornment position="start">
            </InputAdornment >
          }
        />
        <DoneIcon />
        </div>
      </div>

      <div className=" rounded-lg p-2 flex items-center justify-center">
      
        <button className="border-2 border-red-800 rounded-md p-1 text-sm font-medium text-red-800 ">LOG OUT</button>
      </div>
    </div>
  );
};

export default Profile;
