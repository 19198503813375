import axios from "axios"
import { store } from '../redux/store'; // Import Redux store to dispatch actions
import { setWallet } from '../redux/walletSlice'; // Redux wallet action

const api= axios.create({
    baseURL : process.env.REACT_APP_API_LINK,
    withCredentials: true,
})



// Add a response interceptor
api.interceptors.response.use(
    async (response) => {
      const url = response.config.url; // Extract the request URL
  
      try {
        // If the request is for creating a challenge, update the wallet
        if (url.includes('createchallenge')) {
          const walletResponse = await api.get('/wallet/getwalletdetails', { withCredentials: true });
          store.dispatch(setWallet(walletResponse.data.wallet));
        }
  
        // If the request is for fetching available challenges, update Redux
        if (url.includes('availablechallenges')) {
            const walletResponse = await api.get('/wallet/getwalletdetails', { withCredentials: true });
            store.dispatch(setWallet(walletResponse.data.wallet));
        }
  
        // If the request is for fetching active challenges, update Redux
        if (url.includes('activechallenges')) {
            const walletResponse = await api.get('/wallet/getwalletdetails', { withCredentials: true });
            store.dispatch(setWallet(walletResponse.data.wallet));
        }
      } catch (error) {
        console.error('Error in interceptor:', error);
      }
  
      return response; // Return the original response
    },
    (error) => {
      return Promise.reject(error); // Forward any errors to the calling function
    }
  );

export default api;