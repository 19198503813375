// redux/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null, // User state will be managed only in Redux
  isAuthenticated: false, // Authentication state tracked in Redux
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      document.cookie = 'authToken=; Max-Age=0'; // Clear cookie
    },
  },
});

export const { setUser, logout } = userSlice.actions;
export const selectUser = (state) => state.user.user;
export const selectIsAuthenticated = (state) => state.user.isAuthenticated;

export default userSlice.reducer;
