import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import Challengecomp from '../components/Challengecomp';
import Availablecard from '../components/Availablecard';
import api from '../api/api';

const Playground = () => {
  const [amount, setAmount] = useState('');
  const [roomcode, setroomcode] = useState('');
  const [challenges, setChallenges] = useState([]);
  const [avchallenge, setavchallenges] = useState([]);
  const apiUrl = process.env.REACT_APP_API_LINK;

  // Fetch challenges from backend on component mount
  useEffect(() => {
    fetchChallenges();
    fetchAvailableChallenges();
    // Poll for challenges every 5 seconds
    const interval = setInterval(() => {
      fetchChallenges();
      fetchAvailableChallenges();
    }, 50000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const fetchChallenges = async () => {
    try {
      const response = await api.get(`game/activechallenges`);
      setChallenges(response.data);
    } catch (error) {
      console.error('Error fetching challenges:', error);
    }
  };

  const fetchAvailableChallenges = async () => {
    try {
      const response = await api.get(`game/availablechallenges`);
      setavchallenges(response.data);
    } catch (error) {
      console.log("Error fetching challenges ", error);
    }
  };

  const handleCreateChallenge = async () => {
    try {
      await api.post(`game/createchallenge`, { gameAmount: amount, gameId: "1", roomcode:roomcode });
      setAmount(''); // Clear the input field
      setroomcode('')
      fetchChallenges(); // Refresh challenges after creation
    } catch (error) {
      console.error('Error creating challenge:', error);
    }
  };

  const handleAcceptChallenge = async (challengeId) => {
    console.log(challengeId);
    try {
      await api.post(`game/acceptchallenge`, { gameRoomId: challengeId });
      fetchChallenges(); // Refresh challenges after acceptance
    } catch (error) {
      console.error('Error accepting challenge:', error);
    }
  };

  return (
    <div className='playground flex flex-col md:flex-row bg-gray-200 h-lvh w-full'>
      {/* Left Part: Visible on all devices */}
      <div className='w-full md:w-3/12 mb-4 md:mb-0'>
        <div className="top mt-3 mb-5 text-center bg-white rounded-sm">
          <span className='font-semibold text-gray-600'>CREATE A CHALLENGE!</span>
          <div className='justify-center items-center p-4'>
            <TextField
              id="outlined-basic"
              label="Amount"
              variant="outlined"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="roomcode"
              variant="outlined"
              value={roomcode}
              onChange={(e) => setroomcode(e.target.value)}
            />
            <Button
              variant="contained"
              color="success"
              size='large'
              sx={{ marginLeft: "8px", backgroundColor: "rgb(153, 27, 27)" }}
              onClick={handleCreateChallenge}
            >
              SET
            </Button>
          </div>
        </div>

        <span className='font-semibold text-gray-600 m-5'>OPEN CHALLENGES!</span>
        <div className="mid bg-red-800 text-center justify-center items-center">
          {avchallenge.map((challenge) => (
            <Availablecard
              key={challenge._id} // Ensure unique keys
              challenge={challenge}
              handleAcceptChallenge={() => handleAcceptChallenge(challenge._id)} // Passing challenge ID correctly
            />
          ))}
        </div>

        <span className='font-semibold text-gray-600 m-5'>RUNNING MATCH!</span>
        <div className="mid bg-red-800 text-center w-full justify-center items-center">
          {challenges.map((challenge) => (
            <Challengecomp key={challenge._id} challenge={challenge} />
          ))}
        </div>
      </div>

      {/* Right Part: Hidden on small devices, visible on medium and larger */}
      <div className='hidden md:block w-full md:w-9/12 bg-red-500'>
        <h1 className='text-5xl text-black'>This is the right part</h1>
      </div>
    </div>
  );
};

export default Playground;
