import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';

const Availablecard = ({ challenge, handleAcceptChallenge }) => {
  const { userId1, _id, gameAmount } = challenge; // Destructure props
  const apiUrl = process.env.REACT_APP_API_LINK;

  const [user1, setUser1] = useState('');


  const fetchUserDetails = async (userid) => {  // Remove the object destructuring here
    // console.log(userid)
    try {
        const response = await axios.post(`${apiUrl}user/getuserinfousingid`, { userId: userid }, {
            withCredentials: true
        });
        // console.log(response)
        return response.data.user;
    } catch (error) {
        console.error('Error fetching user details:', error);
    }
};

  useEffect(() => {
    const fetchUsers = async () => {
      const userDetails = await fetchUserDetails({userid:userId1});
      setUser1(userDetails);
    };
    fetchUsers();
  }, [userId1]);

  return (
    <div className='p-5 flex gap-2 border-2 align-center items-center border-white text-white'>
      <div className='flex gap-2 p-2 border align-center items-center rounded-md'>
        <Avatar>{user1 && user1.username.charAt(0).toUpperCase()}</Avatar>
        <b>By:</b> {user1 && user1.username}
      </div>
      <span> Game Amount: {gameAmount}</span>
      <button
        className='border border-white rounded-lg w-24 bg-green-800 p-2 ml-5'
        onClick={() => handleAcceptChallenge(_id)} // Call with the correct challenge ID
      >
        Play
      </button>
    </div>
  );
};

export default Availablecard;
