
import  React,{useState,useEffect} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import Fab from '@mui/material/Fab';
import RollingDiceIcon from './RollingDiceIcon';
import LiveGames from './LiveGames';
import { Box, colors } from '@mui/material';
import axios from 'axios';
const  GameCard=({game})=> {
  const [livechallenge, Setlivechallenge]=React.useState([])
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_LINK;
  
  //error here 
  useEffect(()=>{
    fetchChallenges()
  },[game])

  const handlePlayClick = () => {
    navigate('/playground'); // Navigates to /playground route
  };
  //get the no of active challenges
  const fetchChallenges = async () => {
    try {
      const response = await axios.get(`${apiUrl}game/activechallenges`,{
        withCredentials:true
      });
      Setlivechallenge(response.data);
    } catch (error) {
      console.error('Error fetching challenges:', error);
    }
  };
  const liveChallengeCount=livechallenge.length


  return (
    <Card sx={{ maxWidth: 345, border:'black',border:0.5 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={game["imgSrc"]}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {game["title"]}
          </Typography>
          <LiveGames liveChallengeCount={liveChallengeCount} />
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions sx={{ justifyContent: 'center', backgroundColor: 'white' }}>
      <Box display="flex" justifyContent="center" width="100%" >
        <Fab variant="extended" sx={{ backgroundColor: 'rgb(153, 27, 27)',zIndex:1 }} onClick={handlePlayClick} >
          <RollingDiceIcon />
          <Button sx={{ color: 'white' , }}   >Play</Button>
        </Fab>
      </Box>
    </CardActions>
    </Card>
  );
}

export default GameCard;
