// data.js
import { LuSwords } from "react-icons/lu";
import { TbLivePhoto } from "react-icons/tb";

// data.js
export const gamesData = [
    {
      id: 1,
      title: "Yodha Ludo",
      imgSrc: "https://cdn.pixabay.com/photo/2015/07/10/11/11/luck-839036_640.jpg",
      status: "Live",
      link: "/playground", // Link to the specific game page
    },
    // {
    //   id: 2,
    //   title: "Yodha Ludo",
    //   imgSrc: "https://cdn.pixabay.com/photo/2024/03/08/11/09/tokens-8620520_640.png",
    //   status: "Live",
    //   link: "/yodha-ludo-2", // Another specific game page
    // },
    // {
    //   id: 3,
    //   title: "Yodha Ludo",
    //   imgSrc: "https://cdn.pixabay.com/photo/2021/11/10/18/09/casino-6784518_640.jpg",
    //   status: "Live",
    //   link: "/yodha-ludo-3", // Another specific game page
    // },
    // {
    //   id: 4,
    //   title: "Yodha Ludo",
    //   imgSrc: "https://cdn.pixabay.com/photo/2016/03/17/23/09/roulette-1264078_640.jpg",
    //   status: "Main",
    //   link: "/yodha-ludo-43", // Another specific game page
    // },
  ];


  export const dummyData = [
    {
      _id: "64f2b5c9f9b7d3b7a73329d1", // Manually added _id
      userId1: "66c6300b4309fde4ddb062c9", // example ObjectId
      userId2: "64f2b5c9f9b7d3b7a73329d2", // example ObjectId
      status: 'Completed',
      gameAmount: 100,
      gameId: 'game1',
      inGameTransactionHistory: ["64f2b5c9f9b7d3b7a73329d3"],
      result: 'Win',
      createdAt: new Date('2024-09-01'),
    },
    {
      _id: "64f2b5c9f9b7d3b7a73329d4", // Manually added _id
      userId1: "66c6300b4309fde4ddb062c9", // example ObjectId
      userId2: "64f2b5c9f9b7d3b7a73329d5", // example ObjectId
      status: 'Completed',
      gameAmount: 200,
      gameId: 'game2',
      inGameTransactionHistory: ["64f2b5c9f9b7d3b7a73329d6"],
      result: 'Lose',
      createdAt: new Date('2024-09-05'),
    },
    {
      _id: "64f2b5c9f9b7d3b7a73329d7", // Manually added _id
      userId1: "66c6300b4309fde4ddb062c9", // example ObjectId
      userId2: "64f2b5c9f9b7d3b7a73329d8", // example ObjectId
      status: 'Canceled',
      gameAmount: 150,
      gameId: 'game3',
      inGameTransactionHistory: ["64f2b5c9f9b7d3b7a73329d9"],
      result: 'Canceled',
      createdAt: new Date('2024-09-10'),
    },
    {
      _id: "64f2b5c9f9b7d3b7a73329d1", // Manually added _id
      userId1: "66c6300b4309fde4ddb062c9", // example ObjectId
      userId2: "64f2b5c9f9b7d3b7a73329d2", // example ObjectId
      status: 'Completed',
      gameAmount: 100,
      gameId: 'game1',
      inGameTransactionHistory: ["64f2b5c9f9b7d3b7a73329d3"],
      result: 'Win',
      createdAt: new Date('2024-09-01'),
    },
    {
      _id: "64f2b5c9f9b7d3b7a73329d4", // Manually added _id
      userId1: "66c6300b4309fde4ddb062c9", // example ObjectId
      userId2: "64f2b5c9f9b7d3b7a73329d5", // example ObjectId
      status: 'Completed',
      gameAmount: 200,
      gameId: 'game2',
      inGameTransactionHistory: ["64f2b5c9f9b7d3b7a73329d6"],
      result: 'Lose',
      createdAt: new Date('2024-09-05'),
    },
    {
      _id: "64f2b5c9f9b7d3b7a73329d7", // Manually added _id
      userId1: "66c6300b4309fde4ddb062c9", // example ObjectId
      userId2: "64f2b5c9f9b7d3b7a73329d8", // example ObjectId
      status: 'Canceled',
      gameAmount: 150,
      gameId: 'game3',
      inGameTransactionHistory: ["64f2b5c9f9b7d3b7a73329d9"],
      result: 'Canceled',
      createdAt: new Date('2024-09-10'),
    },
    {
      _id: "64f2b5c9f9b7d3b7a73329d1", // Manually added _id
      userId1: "66c6300b4309fde4ddb062c9", // example ObjectId
      userId2: "64f2b5c9f9b7d3b7a73329d2", // example ObjectId
      status: 'Completed',
      gameAmount: 100,
      gameId: 'game1',
      inGameTransactionHistory: ["64f2b5c9f9b7d3b7a73329d3"],
      result: 'Win',
      createdAt: new Date('2024-09-01'),
    },
    {
      _id: "64f2b5c9f9b7d3b7a73329d4", // Manually added _id
      userId1: "66c6300b4309fde4ddb062c9", // example ObjectId
      userId2: "64f2b5c9f9b7d3b7a73329d5", // example ObjectId
      status: 'Completed',
      gameAmount: 200,
      gameId: 'game2',
      inGameTransactionHistory: ["64f2b5c9f9b7d3b7a73329d6"],
      result: 'Lose',
      createdAt: new Date('2024-09-05'),
    },
    {
      _id: "64f2b5c9f9b7d3b7a73329d7", // Manually added _id
      userId1: "66c6300b4309fde4ddb062c9", // example ObjectId
      userId2: "64f2b5c9f9b7d3b7a73329d8", // example ObjectId
      status: 'Canceled',
      gameAmount: 150,
      gameId: 'game3',
      inGameTransactionHistory: ["64f2b5c9f9b7d3b7a73329d9"],
      result: 'Canceled',
      createdAt: new Date('2024-09-10'),
    }
  ];