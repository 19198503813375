import React, {useEffect, useState} from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import HomePage from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Playground from "./pages/Playground";
import Layout from "./components/Layout";
import api from "./api/api";
import './index.css';
import Wallet from "./pages/Wallet";
import Gamehistory from "./pages/Gamehistory";

import { useDispatch } from 'react-redux';
import { setUser } from './redux/userSlice';
import { setWallet } from './redux/walletSlice';

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true); // Loading state to block rendering until data is ready

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Fetch user and wallet data from backend if token is valid
        const userResponse = await api.get('/user/getuserinfo');
        const walletResponse = await api.get('/wallet/getwalletdetails');

        // Store user and wallet data in Redux
        dispatch(setUser(userResponse.data.user));
        dispatch(setWallet(walletResponse.data.wallet));
      } catch (error) {
        console.error('Error fetching user or wallet:', error);
      } finally {
        setLoading(false); // Stop loading once data is fetched or failed
      }
    };

    fetchUserData(); // Fetch data on app load or refresh
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>; // Show loading until user data is fetched
  }

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/playground"
          element={
            <ProtectedRoute>
              <Playground />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wallet"
          element={
            <ProtectedRoute>
              <Wallet />
            </ProtectedRoute>
          }
        />
        <Route
          path="/game-history"
          element={
            <ProtectedRoute>
              <Gamehistory />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Layout>
  );
};

export default App;