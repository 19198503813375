import React from 'react';
import Pagination from '@mui/material/Pagination';
import { dummyData } from "../Data/Data";
import { format } from 'date-fns'; // Optional: date formatting library
import { useSelector } from "react-redux"; 
import { selectWallet } from "../redux/walletSlice"; // Assuming you have this selector

const Gamehistory = () => {
  const wallet = useSelector(selectWallet); // Get wallet data from Redux
  const { totalAmount } = wallet || {}; // Destructure totalAmount from wallet

  return (
    <div>
      <div className="flex items-center justify-center m-2">
        <Pagination count={5} sx={{ color: 'red' }} />
      </div>

      {dummyData.map((game, index) => (
        <div key={game._id || index} className="border justify-between m-2 p-3">
          {/* Date */}
          <div className='flex items-center justify-between'>
            <div>
           
           {format(new Date(game.createdAt), 'MMM dd, yyyy ')}
           </div>
           <div>
            {game.result === 'Win' ? `(+) ${game.gameAmount}` : `(-) ${game.gameAmount}`}
            <br />
            {/* Show the totalAmount from wallet as the closing balance */}
            Closing Balance: {totalAmount - game.gameAmount} {/* Update balance logic if needed */}
          </div>
            
          </div>
         

          {/* Game Data (e.g., Opponent info, Battle ID) */}
          <div className='flex items-center justify-center border p-4 '>
            {game.result === 'Win'
              ? `Won against Player_${game.userId2}`
              : `Lost against Player_${game.userId2}`}
            <br />
            Battle ID: {game._id || 'N/A'}
          </div>

         
        </div>
      ))}

      <div></div>
    </div>
  );
};

export default Gamehistory;
